
import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const Login = lazy(() => import("../views/Login"));

const Product = lazy(() => import("../views/ui/Product"));
const AddProduct = lazy(() => import("../views/ui/AddProduct"));
const ProductDetail = lazy(() => import("../views/ui/ProductDetail"));

const AddCategory = lazy(() => import("../views/ui/AddCategory"));
const Category = lazy(() => import("../views/ui/Category"));

const SubCategory = lazy(() => import("../views/ui/SubCategory"));
const AddSubCategory = lazy(() => import("../views/ui/AddSubCategory"));


const Currency = lazy(() => import("../views/ui/Currency"));
const AddCurrency = lazy(() => import("../views/ui/AddCurrency"));

const State = lazy(() => import("../views/ui/State"));
const AddState = lazy(() => import("../views/ui/AddState"));

const ShippimentPrice = lazy(() => import("../views/ui/ShippmentPrice"));
const AddShippimentPrice = lazy(() => import("../views/ui/AddShippmentPrice"));



const MainCategory = lazy(() => import("../views/ui//MainCategory"));
const AddMainCategory = lazy(() => import("../views/ui/AddMainCategory"));

const Banner = lazy(() => import("../views/ui/Banner"));
const AddBanner = lazy(() => import("../views/ui/AddBanner"));





const PendingOrders = lazy(() => import("../views/ui/PendingOrders"));

const ContactMessages = lazy(() => import("../views/ui/ContactMessages.js"));
// const CompletedOrders = lazy(() => import("../views/ui/CompletedOrders"));
/*****Routes******/

export const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", exact: true, element: <Starter /> },

      { path: "/product", exact: true, element: <Product /> },
      { path: "/product/detail/:id", exact: true, element: <ProductDetail /> },
      { path: "/product/add-product", exact: true, element: <AddProduct /> },
      { path: "/product/edit/:id", exact: true, element: <AddProduct /> },

      { path: "/category", exact: true, element: <Category /> },
      { path: "/category/add-category", exact: true, element: <AddCategory /> },
      { path: "/category/edit/:id", exact: true, element: <AddCategory /> },

      { path: "/subcategory/add-subcategory", exact: true, element: <AddSubCategory /> },
      { path: "/subcategory/edit/:id", exact: true, element: <AddSubCategory /> },
      { path: "/subcategory", exact: true, element: <SubCategory /> },

      { path: "/currency", exact: true, element: <Currency /> },
      { path: "/currency/add-currency", exact: true, element: <AddCurrency /> },
      { path: "/currency/edit/:id", exact: true, element: <AddCurrency /> },
      
      { path: "/state", exact: true, element: <State /> },
      { path: "/state/add-state", exact: true, element: <AddState /> },
      { path: "/state/edit/:id", exact: true, element: <AddState /> },
   
      { path: "/shippment-prices", exact: true, element: <ShippimentPrice /> },
      { path: "/shippment-prices/add", exact: true, element: <AddShippimentPrice /> },
      { path: "/shippment-prices/edit/:id", exact: true, element: <AddShippimentPrice /> },

      
      { path: "/main-category", exact: true, element: <MainCategory /> },
      { path: "/main-category/add", exact: true, element: <AddMainCategory /> },
      { path: "/main-category/edit/:id", exact: true, element: <AddMainCategory /> },
    
      { path: "/banner", exact: true, element: <Banner /> },
      { path: "/banner/add", exact: true, element: <AddBanner /> },
      { path: "/banner/edit/:id", exact: true, element: <AddBanner /> },



      { path: "/pending-orders", exact: true, element: <PendingOrders /> },
      
      { path: "/contact-us", exact: true, element: <ContactMessages /> },
      // { path: "/completed-orders", exact: true, element: <CompletedOrders /> },

    ],
  },
];



// export default ThemeRoute

export const Beforeroutes = [
  {
    path: "/",
    // element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/login" /> },
      { path: "/login", exact: true, element: <Login /> },
    ],
  },
];