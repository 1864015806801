import "./assets/style/style.css"
import 'react-toastify/dist/ReactToastify.css';
import { useRoutes } from "react-router-dom";
import  { ThemeRoutes } from "./routes/Router";
import {Beforeroutes} from "./routes/Router";
import { useSelector } from "react-redux";
import 'bootstrap/dist/js/bootstrap.min.js';
import { ToastContainer } from "react-toastify";
const App = () => {

  const { token } = useSelector((state) => state.auth);


  const routing = useRoutes(ThemeRoutes);
  const beforeLoginRouting = useRoutes(Beforeroutes);

  if (!token)
  { 
    return <div className="dark">{beforeLoginRouting}</div>;
  } 
  else {
    return(
     <div className="dark">
      {routing}
      <ToastContainer />
      </div>
         )}
  
};

export default App;
