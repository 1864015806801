import { createSlice } from '@reduxjs/toolkit'

const initialStateValue = {
  token:"",
  name:"",
  usertype:""
}

export const authSlice = createSlice({
 name: 'authentication',
 initialState: initialStateValue,
 reducers: {
      login: (state,action) =>{
          state.token = action.payload.token;
          state.name = action.payload.name;
          state.usertype = action.payload.usertype;
          
      },

      logout:(state) =>{
        state.token = "";
        state.name = "";
        state.usertype = "";
      },
    //   isAdminn: (state, action) => {
    //       state.admin = action.payload
    //    },
    //    userDetail: (state, action) => {
    //     state.user = action.payload
    //  },
    },
})


// Action creators are generated for each case reducer function
export const { login, logout} = authSlice.actions

export default authSlice.reducer
